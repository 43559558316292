<template>
  <div v-if="!isFormSubmitted">
    <b-card>
      <b-form @submit.prevent v-if="showForm">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="name"
              :label="$t(`${moduleSlug}.name-label`)"
              label-for="name"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.name.$error }"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :placeholder="$t(`${moduleSlug}.name-placeholder`)"
                trim
                :class="{ 'is-invalid': $v.formData.name.$error }"
              ></b-form-input>
              <b-form-invalid-feedback id="name-feedback">
                {{ $t(`${moduleSlug}.name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="site"
              :label="$t(`${moduleSlug}.select_site-label`)"
              label-for="site"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.sites.$error }"
            >
              <b-dropdown
                id="dropdown-form"
                :text="$t(`${moduleSlug}.select_site-placeholder`)"
                ref="dropdown"
                block
                menu-class="w-100 scrollable"
                no-caret
                lazy
                variant="brand-dropdown"
                :toggle-class="{ 'is-invalid': $v.formData.sites.$error }"
                :class="{ 'is-invalid': $v.formData.sites.$error }"
              >
                <b-dropdown-form @submit.stop.prevent>
                  <b-form-checkbox-group
                    class="scrollable"
                    id="sites"
                    v-model="formData.sites"
                    :options="siteOptions"
                    name="sites"
                  ></b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
              <b-form-invalid-feedback id="name-feedback">
                {{ $t(`${moduleSlug}.select_site-required-error-msg`) }}
              </b-form-invalid-feedback>
              <div class="row mt-3" v-if="formData.sites.length">
                <div class="col-12">
                  <div class="tags-group">
                    <div
                      class="tag tags_title"
                      v-for="(_selected, index) in formData.sites"
                      :key="'site_' + index"
                    >
                      <div class="d-flex align-items-center">
                        <span class="align-middle mr-1">
                          {{ _selected.siteId }} - {{ _selected.siteName }}
                        </span>
                        <i
                          class="icon-close"
                          @click="deleteSite(index)"
                          role="button"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="startDate" role="group" class="form-group">
              <label for="startDate" class="form-label required">{{
                $t(`${moduleSlug}.start_date-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="startDate-tootltip"></i>
              <b-tooltip target="startDate-tootltip"
                ><p v-html="$t(`${moduleSlug}.start_date-tooltip`)"
              /></b-tooltip>
              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceStartDate"
                  class="flex-grow"
                  :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
                  :masks="masks"
                  v-model="formData.priceStartDate"
                  is-required
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start: new Date(formData.priceStartDate),
                    end: new Date(formData.priceStartDate),
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        :value="inputValue"
                        @click="showStartDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showStartDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

              <b-form-invalid-feedback id="startDate-feedback">
                {{
                  formData.priceStartDate &&
                  $v.formData.priceStartDate.$error &&
                  $v.formData.priceStartDate.minValue == false
                    ? sytemMessages.minimumDateError
                    : sytemMessages.startDateRequired
                }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div id="endDate" role="group" class="form-group">
              <label for="endDate" class="form-label">{{
                $t(`${moduleSlug}.end_date-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="endDate-tootltip"></i>
              <b-tooltip target="endDate-tootltip"
                ><p v-html="$t(`${moduleSlug}.end_date-tooltip`)"
              /></b-tooltip>
              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceEndDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceEndDate"
                  class="flex-grow"
                  :masks="masks"
                  v-model="formData.priceEndDate"
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : new Date(),
                    end:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : null,
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        :value="inputValue"
                        @click="showEndDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showEndDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <b-form-invalid-feedback id="priceEndDate-feedback">
                {{ $t(`${moduleSlug}.end_date-invalid-error-msg`) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div
          id="csvFileDiv"
          class="form-group"
          role="group"
          :class="{
            'form-group--error':
              $v.formData.csvFile.$error ||
              invalidCSV ||
              duplicateCSVData ||
              invalidCSVSites ||
              emptyCSV ||
              emptySiteIdCsv ||
              emptyVmnCsv ||
              emptyCompareAtPriceCsv ||
              emptyPriceCsv ||
              invalidCSVData,
          }"
        >
          <label class="form-label required">{{
            $t(`${moduleSlug}.import_csv-label`)
          }}</label>
          <div
            class="d-flex align-items-start flex-column"
            :class="{
              'is-invalid':
                $v.formData.csvFile.$error ||
                invalidCSV ||
                duplicateCSVData ||
                invalidCSVSites ||
                emptyCSV ||
                emptySiteIdCsv ||
                emptyVmnCsv ||
                emptyCompareAtPriceCsv ||
                emptyPriceCsv ||
                invalidCSVData,
            }"
          >
            <div class="template-div mb-3">
              <b-link
                @click="downloadCSVTemplate"
                class="btn btn-brand-secondary"
                >{{ $t(`${moduleSlug}.download_csv_template-label`) }}</b-link
              >
            </div>
            <div
              class="upload-btn-wrapper d-flex align-items-md-center flex-column flex-md-row align-items-sm-start"
            >
              <div>
                <b-form-file
                  ref="csvFile"
                  id="csvFile"
                  v-model="formData.csvFile"
                  plain
                  hidden
                  accept=".csv"
                />
                <!-- our custom upload button -->
                <label
                  for="csvFile"
                  class="btn btn-brand-secondary mb-2 mb-md-0"
                  >{{ $t(`${moduleSlug}.csv_browse-label`) }}</label
                >
              </div>
              <!-- name of file chosen -->
              <span id="file-chosen"
                >{{
                  formData.csvFile
                    ? formData.csvFile.name
                    : $t(`${moduleSlug}.no_csv-placeholder`)
                }}
                <b-link v-if="formData.csvFile" @click="removeCSVFile">
                  <i class="icon-close"></i>
                </b-link>
              </span>
            </div>
          </div>

          <b-form-invalid-feedback id="csvFile-feedback" v-if="invalidCSV">
            {{ $t(`${moduleSlug}.invalid_csv-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback id="csvFile-feedback" v-else-if="emptyCSV">
            {{ $t(`${moduleSlug}.empty_csv-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="invalidCSVSites"
          >
            {{ $t(`${moduleSlug}.not_match_csv_site-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="invalidCSVData"
          >
            {{ $t(`${moduleSlug}.invalid_csv_data-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="duplicateCSVData"
          >
            {{ $t(`${moduleSlug}.duplicate_csv_data-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="emptySiteIdCsv"
          >
            {{ $t(`${moduleSlug}.missing-siteId-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="emptyVmnCsv"
          >
            {{ $t(`${moduleSlug}.missing-vmn-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="emptyCompareAtPriceCsv"
          >
            {{ $t(`${moduleSlug}.missing-compared_at_cost-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            id="csvFile-feedback"
            v-else-if="emptyPriceCsv"
          >
            {{ $t(`${moduleSlug}.missing-cost-error-msg`) }}
          </b-form-invalid-feedback>

          <b-form-invalid-feedback id="csvFile-feedback" v-else>
            {{
              $v.formData.csvFile.fileValidation == false
                ? $t(`${moduleSlug}.invalid_csv_file-error-msg`)
                : $t(`${moduleSlug}.import_csv-error-msg`)
            }}
          </b-form-invalid-feedback>
        </div>
      </b-form>

      <preview-form
        :formName="formData.name"
        isMultisite
        showStartDate
        :startDate="formData.priceStartDate | formatedDate"
        showEndDate
        :endDate="formData.priceEndDate | formatedDate"
        showImportCSVFile
        :importCSVFileName="CSVFileName"
        :moduleSlug="moduleSlug"
        :nameLabel="$t(`${moduleSlug}.name-label`)"
        :siteLabel="$t(`${moduleSlug}.selected_site-label`)"
        :importedFromCSVLabel="$t(`${moduleSlug}.import_csv-label`)"
        :startDateLabel="$t(`${moduleSlug}.start_date-label`)"
        :endDateLabel="$t(`${moduleSlug}.end_date-label`)"
        v-if="showFormPreview"
      />

      <div class="d-flex flex-row-reverse">
        <b-button
          id="validate-btn"
          @click="handleValidate"
          v-if="showForm"
          variant="brand-primary"
          >{{ $t(`${moduleSlug}.validate-btn-label`) }}
        </b-button>
        <b-button
          @click="submitForm"
          id="submit-btn"
          v-if="
            showFormPreview &&
            !productPreviewFailed &&
            isUploadedProductsVerified
          "
          variant="brand-primary"
          >{{ $t(`${moduleSlug}.submit-btn-label`) }}</b-button
        >
        <b-button
          id="edit-btn"
          @click="handleEdit"
          v-if="showFormPreview"
          variant="brand-secondary"
          class="mr-3"
          >{{ $t(`${moduleSlug}.edit-btn-label`) }}</b-button
        >
      </div>
    </b-card>
    <b-card
      v-if="
        productPreviewFailed || showFilteredProducts || showLastModifiedProducts
      "
      class="mt-4"
    >
      <product-preview-failed v-if="productPreviewFailed" />
      <product-preview
        v-if="showFilteredProducts"
        :formName="formName"
        :fileTitle="formData.name"
        :moduleSlug="moduleSlug"
      />
      <div class="product-preview" v-if="showLastModifiedProducts">
        <div class="d-flex flex-row-reverse mb-3">
          <b-link
            class="export-csv my-md-0 my-2"
            :disabled="importHistoryTotalRecords == '00'"
            @click="downloadImportHistory"
            >{{ $t(`general.export-csv`) }}
            <i class="icon-download ml-1"></i>
          </b-link>
        </div>
        <b-table
          hover
          responsive
          :items="importHistoryItems"
          :fields="importHistoryFields"
          :per-page="perPage"
          :current-page="importHistoryCurrentPage"
          tbody-tr-class="text-nowrap"
          show-empty
          :empty-text="$t('general.no-data-msg')"
        >
          <template #head(products)>
            {{ $t(`${moduleSlug}.table_head_products`) }}
          </template>
          <template #head(siteName)>
            {{ $t(`${moduleSlug}.table_head_siteName`) }}
          </template>
          <template #head(sku)>
            {{ $t(`${moduleSlug}.table_head_sku`) }}
          </template>
          <template #head(vnm)>
            {{ $t(`${moduleSlug}.table_head_vmn`) }}
          </template>
          <template #head(cost)>
            {{
              moduleSlug == "promotional-pricing-import"
                ? $t(`${moduleSlug}.table_head_promotionalImportCost`)
                : $t(`${moduleSlug}.table_head_flyerImportCost`)
            }}
          </template>
          <template #head(price)>
            {{
              moduleSlug == "promotional-pricing-import"
                ? $t(`${moduleSlug}.table_head_promotionalImportPrice`)
                : $t(`${moduleSlug}.table_head_flyerImportPrice`)
            }}
          </template>
          <template #head(lastModified)>
            {{ $t(`${moduleSlug}.table_head_lastmodified`) }}
          </template>

          <template #cell(products)="data">
            <b-img
              src="/media/icons/rectangle.svg"
              alt="Product Rectangle Icon"
            />{{ data.item.ProductName }}
          </template>

          <template #cell(siteName)="data">
            {{ data.item.SiteName }}
          </template>

          <template #cell(sku)="data">
            {{ data.item.ProductSKU }}
          </template>

          <template #cell(vnm)="data">
            {{ data.item.VendorModelNumber }}
          </template>

          <template #cell(cost)="data">
            {{ data.item.RegularPrice }}
          </template>

          <template #cell(price)="data">
            {{ data.item.FlyerPrice }}
          </template>

          <template #cell(lastModified)="data">
            {{ data.item.CreatedOn | formatedHistoryDate }}
          </template>
        </b-table>
        <table-pagination
          class="mt-3"
          :fromLimit="importHistoryFromLimit | addLeadingZero"
          :toLimit="importHistoryToLimit | addLeadingZero"
          :totalRecords="importHistoryTotalRecords | addLeadingZero"
          :currentPage="importHistoryCurrentPage"
          :totalPages="importHistoryTotalPages"
          @paginate="importHistoryPaginate"
          :countInfo="`${moduleSlug}.table_count-info`"
        />
      </div>
    </b-card>
    <b-modal
      dialog-class="success-modal"
      centered
      hide-header
      hide-footer
      ref="success-modal"
    >
      <div
        class="d-flex justify-content-center flex-column align-items-center text-center"
      >
        <i class="font-50 icon-completed mb-2"></i>
        <div class="message-detail">
          <h5 class="msg-title">
            {{ $t(`${moduleSlug}.success_message-title`) }}
          </h5>
          <i18n
            :path="`${moduleSlug}.success_message-desc`"
            tag="p"
            class="msg-desc m-0"
          >
            <b-link to="/pricing-history">{{
              $t(`${moduleSlug}.success_message-desc_term`)
            }}</b-link>
          </i18n>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else>
    <b-card class="mt-4">
      <form-success>
        <h5 class="msg-title">
          {{ $t(`${moduleSlug}.success_message-title`) }}
        </h5>
        <i18n
          :path="`${moduleSlug}.success_message-desc`"
          tag="p"
          class="msg-desc m-0"
        >
          <b-link to="/pricing-history">{{
            $t(`${moduleSlug}.success_message-desc_term`)
          }}</b-link>
        </i18n>
      </form-success>
    </b-card>
  </div>
</template>

<script>
import config from "../../../../config";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import {
  forEach,
  isEqual,
  uniq,
  sortBy,
  includes,
  cloneDeep,
  keys,
  isObject,
  ceil,
  isEmpty,
} from "lodash";
import PreviewForm from "../../../components/PreviewForm.vue";
import ProductPreviewFailed from "../../../components//ProductPreviewFailed.vue";
import ProductPreview from "../../../components/ProductPreview.vue";
import FormSuccess from "../../../components/FormSuccess.vue";
import moment from "moment";
import TablePagination from "../../../components/TablePagination.vue";

const { dateFormat, exportCSVHeaders, tableHeaders } = config;

export default {
  name: "PricingImport",
  components: {
    PreviewForm,
    ProductPreviewFailed,
    ProductPreview,
    FormSuccess,
    TablePagination,
  },
  props: [
    "moduleSlug",
    "formName",
    "controllerName",
    "moduleName",
    "priceTypeId",
  ],
  data() {
    return {
      showForm: true,
      showFormPreview: false,
      showFilteredProducts: false,
      productPreviewFailed: false,
      isFormSubmitted: false,
      invalidCSV: false,
      invalidCSVSites: false,
      invalidCSVData: false,
      duplicateCSVData: false,
      isUploadedProductsVerified: false,
      emptyCSV: false,

      emptySiteIdCsv: false,
      emptyVmnCsv: false,
      emptyCompareAtPriceCsv: false,
      emptyPriceCsv: false,

      csvFileData: [],
      formData: {
        id: 0,
        name: "",
        sites: [],
        csvFile: null,
        priceStartDate: new Date(),
        priceEndDate: "",
      },
      CSVFileName: "",
      filteredProducts: [],
      dateFormat: dateFormat,
      csvFields: exportCSVHeaders[`${this.moduleSlug}-template`],
      // fileTitle: "Flyer Import Template",
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },

      // All the data used for history of import flyer
      importHistoryCurrentPage: 1,
      importHistoryData: [],
      perPage: 20,
      showLastModifiedProducts: false,
      importHistoryFields: tableHeaders[`${this.moduleSlug}-history`],
      importHistoryCsvFields: exportCSVHeaders[`${this.moduleSlug}-history`],
    };
  },
  computed: {
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters(["selected_language"]),
    ...mapGetters("pricingManager", [
      "pricingManagerId",
      "mode",
      "preFilledData",
    ]),
    siteOptions() {
      let options = [];
      forEach(this.siteAccess, (site) => {
        let obj = {
          text: site.Name,
          value: { siteId: site.SiteId, siteName: site.Name },
        };
        options.push(obj);
      });
      return options;
    },
    fileTitle() {
      if (this.formName == "flyer-pricing-import") {
        return "Flyer Import Template";
      } else {
        return "Promotional Pricing Import Template";
      }
    },
    templateCSVHeader() {
      let header = {};
      forEach(this.csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
    // All the computed property used for history of import flyer
    importHistoryItems() {
      return this.importHistoryData;
    },
    importHistoryTotalRecords() {
      return this.importHistoryItems.length;
    },
    importHistoryTotalPages() {
      return ceil(this.importHistoryTotalRecords / this.perPage);
    },
    importHistoryFromLimit() {
      let response = this.getPaginationResultLimit(
        this.importHistoryCurrentPage,
        this.perPage,
        this.importHistoryTotalRecords
      );
      return response.fromlimit;
    },
    importHistoryToLimit() {
      let response = this.getPaginationResultLimit(
        this.importHistoryCurrentPage,
        this.perPage,
        this.importHistoryTotalRecords
      );
      return response.tolimit;
    },
    productsResource() {
      if (this.formName == "flyer-pricing-import")
        return "validFlyerImportData";
      else return "validPromotionalImportData";
    },
  },
  watch: {
    "formData.sites": function (newValue) {
      this.setItem({ resource: "selectedSite", payload: newValue });
    },
    "formData.csvFile": async function (newValue) {
      this.csvFileData = [];
      this.productPreviewFailed = false;
      this.invalidCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.duplicateCSVData = false;
      if (newValue != null) {
        this.CSVFileName = newValue.name;
        let fileContent = await this.createInput(newValue);
        let delimiter = this.csvHelpers.guessDelimiters(fileContent, [
          "\t",
          ",",
        ]);
        if (delimiter.length == 0) {
          this.csvFileData = [];
        } else {
          let jsonContent = JSON.parse(
            this.csvHelpers.CSVToJSON(fileContent, delimiter)
          );

          let _csvFileData = jsonContent.filter((element, index) => {
            return (
              (element.siteId != "" ||
                element.vendorModelNumber != "" ||
                element.compareAtPrice != "" ||
                element.price != "") &&
              index < jsonContent.length - 1
            );
          });

          this.csvFileData = _csvFileData;
        }
      }
    },
    selected_language() {
      this.$v.$reset();
    },
  },
  methods: {
    ...mapActions(["resetFormState"]),
    ...mapMutations(["setItem"]),
    ...mapActions("pricingManager", [
      // "verifyFlatFlyerCsv",
      // "submitFlatFlyerCsv",

      "verifyImportedCsv",
      "submitImportedCsv",
    ]),
    ...mapMutations("pricingManager", {
      pricingSetItem: "setItem",
    }),
    deleteSite(index) {
      this.formData.sites.splice(index, 1);
    },
    removeCSVFile() {
      this.formData.csvFile = null;
      this.invalidCSV = false;
      this.emptyCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.duplicateCSVData = false;

      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyCompareAtPriceCsv = false;
      this.emptyPriceCsv = false;
      // this.$refs["csvFile"].reset();
    },
    showSuccessModal() {
      this.$refs["success-modal"].show();
    },
    hideSuccessModal() {
      this.$refs["success-modal"].hide();
    },
    downloadCSVTemplate() {
      try {
        this.setItem({ resource: "isLoading", payload: true });
        let headers = this.templateCSVHeader;
        let csvData = [];

        if (this.mode == "" || this.mode == null) {
          let _csvData = {};
          forEach(headers, (val, key) => {
            _csvData[key] = "";
          });
          csvData.push(_csvData);
        } else {
          forEach(this.csvFileData, (data) => {
            let _csvData = {};
            forEach(headers, (val, key) => {
              _csvData[key] = "";

              if (key == "siteId") {
                if ("SiteId" in data) {
                  _csvData[key] = String(data.SiteId).replace(/,/g, ""); // remove commas to avoid errors,
                }
              }

              if (key == "sku") {
                if ("ProductSKU" in data) {
                  _csvData[key] = String(data.ProductSKU).replace(/,/g, ""); // remove commas to avoid errors,
                }
              }

              if (
                key == "vnm" ||
                key == "VendorModelNumber" ||
                key == "vendorModelNumber"
              ) {
                if ("VendorModelNumber" in data) {
                  _csvData[key] = String(data.VendorModelNumber).replace(
                    /,/g,
                    ""
                  ); // remove commas to avoid errors,
                }
                if ("vendorModelNumber" in data) {
                  _csvData[key] = String(data.vendorModelNumber).replace(
                    /,/g,
                    ""
                  ); // remove commas to avoid errors,
                }
                if ("vmn" in data) {
                  _csvData[key] = String(data.vmn).replace(/,/g, ""); // remove commas to avoid errors,
                }
              }

              if (key == "compareAtPrice") {
                if ("RegularPrice" in data) {
                  _csvData[key] = String(data.RegularPrice).replace(/,/g, ""); // remove commas to avoid errors,
                }
              }

              if (key == "price") {
                if ("FlyerPrice" in data) {
                  _csvData[key] = String(data.FlyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
                }
              }
            });
            csvData.push(_csvData);
          });
        }

        let fileTitle =
          this.fileTitle + "_" + moment(new Date()).format("YYYY/MM/DD");
        this.setItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.setItem({ resource: "isLoading", payload: false });
      }
    },
    handleValidate() {
      this.productPreviewFailed = false;
      this.invalidCSV = false;
      this.emptyCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.duplicateCSVData = false;

      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyCompareAtPriceCsv = false;
      this.emptyPriceCsv = false;

      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        return;
      } else {
        let siteIds = this.formData.sites.map((a) => a.siteId);

        if (this.csvFileData.length == 0) {
          this.emptyCSV = true;
          return;
        }

        let csvFileDataLocal = cloneDeep(this.csvFileData);

        // let csvHeader = Object.keys(csvFileDataLocal[0]);
        let csvHeader = keys(csvFileDataLocal[0]);
        let templateCSVHeader = keys(this.templateCSVHeader);

        if (csvFileDataLocal.length == 0) {
          this.invalidCSVData = true;
          return;
        }

        let validColumns = csvFileDataLocal.every(
          (value) => Object.keys(value).length === templateCSVHeader.length
        );
        // ['siteId', 'sku', 'compareAtPrice', 'price']
        if (
          (!isEqual(sortBy(csvHeader), sortBy(templateCSVHeader)) &&
            validColumns == true) ||
          validColumns == false
        ) {
          this.invalidCSV = true;
          return;
        }

        const isSiteIdBlank = csvFileDataLocal.every(
          (value) => value.siteId != ""
        );

        if (isSiteIdBlank === false) {
          this.emptySiteIdCsv = true;
          return;
        }

        const isVendorModelNumberBlank = csvFileDataLocal.every(
          (value) => value.vendorModelNumber != ""
        );

        if (isVendorModelNumberBlank === false) {
          this.emptyVmnCsv = true;
          return;
        }

        const isCompareAtPriceBlank = csvFileDataLocal.every(
          (value) => value.compareAtPrice != ""
        );

        if (isCompareAtPriceBlank === false) {
          this.emptyCompareAtPriceCsv = true;
          return;
        }

        const isPriceBlank = csvFileDataLocal.every(
          (value) => value.price != ""
        );

        if (isPriceBlank === false) {
          this.emptyPriceCsv = true;
          return;
        }

        const isNullish = csvFileDataLocal.every((value) => {
          if (
            value.siteId != "" &&
            value.vendorModelNumber != "" &&
            value.compareAtPrice != "" &&
            value.price != ""
          ) {
            return true;
          }

          return false;
        });

        if (!isNullish) {
          this.invalidCSVData = true;
          return;
        }

        let csvSiteIds = uniq(csvFileDataLocal.map((_file) => _file.siteId));

        let invalidSites = [];
        forEach(csvSiteIds, (siteId) => {
          if (!includes(siteIds, Number(siteId))) {
            invalidSites.push(siteId);
          }
        });

        if (invalidSites.length > 0) {
          this.invalidCSVSites = true;
          return;
        }

        // if()

        let invalidData = csvFileDataLocal.filter((_data) => {
          // return Number(_data.compareAtPrice) < Number(_data.price);
          if (Number(_data.compareAtPrice) <= 0) {
            return true;
          } else if (Number(_data.price) <= 0) {
            return true;
          } else if (Number(_data.compareAtPrice) < Number(_data.price)) {
            return true;
          }
        });

        if (invalidData.length > 0) {
          this.invalidCSVData = true;
          return;
        }

        forEach(csvFileDataLocal, (data) => {
          if ("sku" in data) {
            data["productId"] = data["sku"];
            delete data["sku"];
          }
          if ("vendorModelNumber" in data) {
            data["VendorModelNumber"] = data["vendorModelNumber"];
            delete data["vendorModelNumber"];
          }
          if ("price" in data) {
            data["promoPrice"] = data["price"];
            delete data["price"];
          }
          if (data["productId"] == "") {
            delete data["productId"];
          }
        });

        let validateDuplicateCSVData =
          this.validateDuplicateCSVData(csvFileDataLocal);

        if (!validateDuplicateCSVData) {
          this.duplicateCSVData = true;
          return;
        }

        const h = this.$createElement;

        const titleVNode = h("h5", {
          class: ["modal-title font-weight-bold"],
          domProps: {
            innerHTML: this.$t(`${this.moduleSlug}.upload_confirmation-title`),
          },
        });

        const messageVNode = h("p", { class: ["text-center m-0 font-14"] }, [
          // this.sytemMessages.csvFileUploadWarning,
          this.$t(`${this.moduleSlug}.upload_confirmation-msg`),
        ]);

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            // title: "Caution",
            title: [titleVNode],
            size: "sm",
            dialogClass: "confirm-import-modal",
            buttonSize: "sm",
            okVariant: "brand-primary w-50 confirm-btn",
            cancelVariant: "brand-secondary border-0 w-50 cancel-btn",
            okTitle: this.$t(
              `${this.moduleSlug}.upload_confirmation_proceed_btn-label`
            ),
            cancelTitle: this.$t(
              `${this.moduleSlug}.upload_confirmation_cancel_btn-label`
            ),
            headerClass: "justify-content-center p-0 mt-3 border-bottom-0",
            footerClass: "p-3 border-top-0 flex-nowrap",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then((value) => {
            if (value) {
              let _priceEndDate = "2099-12-31T00:00:00Z";

              this.formData.priceStartDate = new Date(
                this.formData.priceStartDate
              ).toISOString();

              this.formData.priceEndDate =
                this.formData.priceEndDate == "" ||
                this.formData.priceEndDate == null
                  ? _priceEndDate
                  : new Date(this.formData.priceEndDate).toISOString();

              this.setItem({ resource: "isLoading", payload: true });
              this.verifyImportedCsv({
                data: csvFileDataLocal,
                exceptionParams: {
                  userId: this.loggedUserId,
                  controllerName: this.controllerName,
                  moduleName: this.moduleName,
                },
              })
                .then((response) => {
                  this.setItem({ resource: "isLoading", payload: false });
                  if (response.status == 1) {
                    if (response.data.length == 0) {
                      this.productPreviewFailed = true;
                    } else {
                      this.filteredProducts = response.data;
                      this.pricingSetItem({
                        resource: this.productsResource,
                        payload: response.data,
                      });
                      this.isUploadedProductsVerified = response.data.every(
                        (item) => item.status == 1
                      );
                      this.showForm = false;
                      this.showFilteredProducts = this.showFormPreview = true;
                    }
                  }
                })
                .catch(() => {
                  this.setItem({ resource: "isLoading", payload: false });
                });
            }
          })
          .catch(() => {
            // An error occurred
            this.setItem({ resource: "isLoading", payload: false });
          });
      }
    },
    handleEdit() {
      this.showForm = true;
      this.showLastModifiedProducts =
        this.showFormPreview =
        this.showFilteredProducts =
        this.productPreviewFailed =
          false;
      this.filteredProducts = this.importHistoryData = [];
      if (
        this.formData.priceEndDate == "2099-12-31T00:00:00" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00Z" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.priceEndDate = null;
      }
    },
    async submitForm() {
      this.setItem({ resource: "isLoading", payload: true });
      let filteredProducts = this.filteredProducts;
      if (this.mode == "edit" && this.importHistoryData.length > 0) {
        filteredProducts = [];
        forEach(this.importHistoryData, (data) => {
          let obj = {
            compareAtPrice: data.RegularPrice,
            productId: data.ProductSKU,
            productName: data.ProductName,
            promoPrice: data.FlyerPrice,
            siteId: data.SiteId,
            siteName: data.SiteName,
            status: 1,
            statusMessage: "SUCCESS",
            vendorModelNumber: data.VendorModelNumber,
          };

          filteredProducts.push(obj);
        });
      }

      let final_filteredProducts = filteredProducts.filter(
        (item) => item.status == 1
      );

      let submitData = {
        id: this.formData.id,
        userId: this.loggedUserId,
        name: this.formData.name,
        priceTypeId: this.priceTypeId,
        priceStartDate: this.formData.priceStartDate,
        priceEndDate: this.formData.priceEndDate,
        pricingData: final_filteredProducts,

        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      };

      this.submitImportedCsv(submitData)
        .then((response) => {
          if (response.status == 1) {
            // this.isFormSubmitted = true;
            this.showSuccessModal();
            this.resetForm();
          }
          this.setItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    async createInput(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
      });
    },
    resetForm() {
      this.resetFormState();
      this.$v.$reset();

      this.showForm = true;
      this.showFormPreview = false;
      this.showFilteredProducts = false;
      this.productPreviewFailed = false;
      this.isFormSubmitted = false;
      this.invalidCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.emptyCSV = false;

      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyCompareAtPriceCsv = false;
      this.emptyPriceCsv = false;
      this.duplicateCSVData = false;

      this.formData = {
        id: 0,
        name: "",
        sites: [],
        csvFile: null,
        priceStartDate: new Date(),
        priceEndDate: null,
      };
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
        this.$refs.priceStartDate.togglePopover();
      });
    },
    showEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
        this.$refs.priceEndDate.togglePopover();
      });
    },
    hideStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
      });
    },
    hideEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
      });
    },
    importHistoryPaginate(eventType) {
      if (eventType == "next") {
        this.importHistoryCurrentPage = this.importHistoryCurrentPage + 1;
      } else {
        this.importHistoryCurrentPage = this.importHistoryCurrentPage - 1;
      }
    },
    downloadImportHistory() {
      // flyer-pricing-import-history
      try {
        this.setItem({ resource: "isLoading", payload: true });
        let headers = {};
        forEach(this.importHistoryCsvFields, (field) => {
          headers[field.key] = field.label;
        });

        let csvData = [];

        forEach(this.importHistoryData, (data) => {
          let _csvData = {};
          forEach(headers, (val, key) => {
            _csvData[key] = "";

            if (key == "products") {
              if ("ProductName" in data) {
                _csvData[key] = String(data.ProductName).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "siteName") {
              if ("SiteName" in data) {
                _csvData[key] = String(data.SiteName).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "sku") {
              if ("ProductSKU" in data) {
                _csvData[key] = String(data.ProductSKU).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "vnm" || key == "VendorModelNumber") {
              if ("VendorModelNumber" in data) {
                _csvData[key] = String(data.VendorModelNumber).replace(
                  /,/g,
                  ""
                ); // remove commas to avoid errors,
              }
              if ("vnm" in data) {
                _csvData[key] = String(data.vnm).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "cost") {
              if ("RegularPrice" in data) {
                _csvData[key] = String(data.RegularPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "price") {
              if ("FlyerPrice" in data) {
                _csvData[key] = String(data.FlyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "lastModified") {
              if ("CreatedOn" in data) {
                let _date = String(data.CreatedOn).replace(/,/g, "");
                _csvData[key] = `${moment(_date).format("DD-MMM-YYYY h:mm A")}`; // remove commas to avoid errors,
              }
            }
          });
          csvData.push(_csvData);
        });

        let fileTitle =
          this.formData.name + "_" + moment(new Date()).format("YYYY/MM/DD");
        this.setItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.setItem({ resource: "isLoading", payload: false });
      }
    },
  },
  validations: {
    formData: {
      name: { required },
      sites: { required, minLength: minLength(1) },
      csvFile: {
        required,
        fileValidation(value) {
          if (value != null) {
            // let allowedExtensions = ['xlsx','xls','csv'];
            let allowedExtensions = ["csv"];
            let fileExtension = value.name
              .substring(value.name.lastIndexOf(".") + 1)
              .toLowerCase();
            if (allowedExtensions.indexOf(fileExtension) <= -1) {
              return false;
            }
          }
          return true;
        },
      },
      priceStartDate: {
        required,
        minValue(value) {
          let sdate = moment(value);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = "";

          if (!isEmpty(this.formData.priceEndDate)) {
            edate = moment(this.formData.priceEndDate);
            edate.set("hour", 0);
            edate.set("minute", 0);
            edate.set("second", 0);
            edate.set("millisecond", 0);

            if (
              moment(edate)._i == "2099-12-31T00:00:00" ||
              moment(edate)._i == "2099-12-31T00:00:00Z" ||
              moment(edate)._i == "2099-12-31T00:00:00.000Z"
            ) {
              edate = "";
            }
          }

          let status = this.checkStartDateValidation(sdate, edate, this.mode);

          return status;
        },
      },
      priceEndDate: {
        minValue(value) {
          let sdate = moment(this.formData.priceStartDate);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = moment(value);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }

          let status = this.checkEndDateValidation(sdate, edate);

          return status;
        },
      },
    },
  },
  mounted() {
    this.resetForm();

    let preFilledData = this.preFilledData;

    if (
      preFilledData &&
      isObject(preFilledData) &&
      Object.keys(preFilledData).length > 0
    ) {
      // let productCondition = preFilledData.productcondition;
      // let flyerPricing = productCondition[0].flyerpricing;

      let flyerPricing = preFilledData.flyerpricing;

      this.CSVFileName = preFilledData.Name + ".csv";

      this.formData.id = this.mode == "edit" ? preFilledData.Id : 0;
      this.formData.name =
        this.mode == "edit"
          ? preFilledData.Name
          : "Copy of " + preFilledData.Name;

      this.formData.priceStartDate = new Date(preFilledData.PriceStartDate);

      if (
        preFilledData.PriceEndDate == "2099-12-31T00:00:00" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00Z" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        let date = moment(preFilledData.PriceEndDate);
        date.set("hour", 0);
        date.set("minute", 0);
        date.set("second", 0);
        date.set("millisecond", 0);

        if (this.mode == "clone") this.formData.priceEndDate = null;
        else this.formData.priceEndDate = date;
      } else {
        this.formData.priceEndDate = new Date(preFilledData.PriceEndDate);
      }

      if (flyerPricing && flyerPricing.length) {
        let csvSiteIds = uniq(flyerPricing.map((a) => a.SiteId));
        let selectedSites = [];
        this.siteOptions.forEach((site) => {
          if (csvSiteIds.includes(site.value.siteId)) {
            selectedSites.push(site.value);
          }
        });

        this.formData.sites = selectedSites;

        this.csvFileData = this.importHistoryData = flyerPricing;
      }

      if (this.mode == "edit") {
        this.showForm = false;
        this.showLastModifiedProducts = this.showFormPreview = true;
      }
    }
  },
  beforeDestroy() {
    this.resetForm();
    this.pricingSetItem({ resource: "pricingManagerId", payload: 0 });
    this.pricingSetItem({ resource: "mode", payload: "" });
    this.pricingSetItem({ resource: "preFilledData", payload: null });
  },
};
</script>
